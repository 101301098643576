import React, { Component } from 'react';
import Frame from "../../components/frame";
import TopHeader from '../../components/TopHeader';
import {withRouter} from "react-router-dom";
import { makeStyles, withStyles, styled } from '@material-ui/core/styles';
import { withSnackbar } from 'notistack';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import BestBNB from "../../contracts/BestBNB.json";
import getWeb3 from "../../getWeb3";
import { withTranslation } from 'react-i18next';
const defaultAmountArr = ['1','2','4','8','16','32'];

class Home extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        loading:true,
        web3: null,
        accounts: null,
        contract: null,
        contractAddress: null,
        sponsor_address: (this.props.match.params.referral != 'undefined') ? this.props.match.params.referral : '',
        userInfo:{},
        TotalBonus:0,
        totalWithdrawn:0,
        totalDeposits:0,
        totalInvested:0,
        totalUsers:0,
        networkMain:false,
        YourAddress: '-',
        YourAddressfull: null,
        amountBNB:null,
        user_referer:'-',
        balance:null,
        adminFee:null,
        adminFee_percentage:5,
        reinvest_percentage:0,
        withdrwal_percentage:0,
        GetDownlineIncomeByUserId:0,
        GetUplineIncomeByUserId:0,
        communityLevels:[],
        sponsorList:[]
      }
    }

    componentDidMount = async () => {
      try {
        // Get network provider and web3 instance.
        const web3 = await getWeb3();
  
        // Use web3 to get the user's accounts.
        setInterval(async()=>{
          const accounts = await web3.eth.getAccounts();
         
          if(this.state.accounts !== accounts[0]){
          // Get the contract instance.
          const networkId = await web3.eth.net.getId();
          //alert(networkId);
          //if(networkId !== 56) throw "Please connect Mainnet"; 
          const deployedNetwork = BestBNB.networks[networkId];
          const instance = new web3.eth.Contract(
            BestBNB.abi,
            deployedNetwork && deployedNetwork.address,
          );   
          
          this.setState({ loading:false, web3, accounts:accounts[0], contract: instance, contractAddress:deployedNetwork.address}, this.fetchData);
          }
       },1000);

      } catch (error) {
        // Catch any errors for any of the above operations.
        // alert(
        //   error
        // );
        this.setState({networkMain:false, loading:false});
        console.error(error);
      }
    };

    fetchData = async() => {
      const { accounts, contract } = this.state;

      console.log(this.state.contractAddress);

      let YourAddress = accounts.slice(0, 10) + '.....' + accounts.slice(accounts.length-5, accounts.length);
      let YourAddressfull = accounts;
      let userInfo = await contract.methods.users(accounts).call();
      userInfo.amount = this.state.web3.utils.fromWei(userInfo.amount, 'ether');
      userInfo.amount = parseFloat(userInfo.amount).toFixed(5);
      userInfo.referrerBonus = this.state.web3.utils.fromWei(userInfo.referrerBonus, 'ether');
      userInfo.referrerBonus = parseFloat(userInfo.referrerBonus).toFixed(5);
      userInfo.totalWithdrawn = this.state.web3.utils.fromWei(userInfo.totalWithdrawn, 'ether');
      userInfo.totalWithdrawn = parseFloat(userInfo.totalWithdrawn).toFixed(5);
      let user_referer = '-';
      if(userInfo.checkpoint != undefined && userInfo.checkpoint > 0){
        user_referer = userInfo.referrer;
        user_referer = user_referer.slice(0, 10) + '.....' + user_referer.slice(user_referer.length-5, user_referer.length);
      }
      
      let TotalBonus = await contract.methods.TotalBonus(accounts).call();
      TotalBonus = this.state.web3.utils.fromWei(TotalBonus, 'ether');
      TotalBonus = parseFloat(TotalBonus).toFixed(5);
      let totalWithdrawn = await contract.methods.totalWithdrawn().call();
      totalWithdrawn = this.state.web3.utils.fromWei(totalWithdrawn, 'ether');
      totalWithdrawn = parseFloat(totalWithdrawn).toFixed(5);
      let totalDeposits = await contract.methods.totalDeposits().call();
      totalDeposits = parseFloat(totalDeposits).toFixed(5);
      let totalInvested = await contract.methods.totalInvested().call();
      totalInvested = this.state.web3.utils.fromWei(totalInvested, 'ether');
      totalInvested = parseFloat(totalInvested).toFixed(5);
      let totalUsers = await contract.methods.totalUsers().call();

      let balance = TotalBonus;
      let adminFee = balance*this.state.adminFee_percentage/100;
      balance = balance - adminFee;


      let getEligibleWithdrawal = await contract.methods.getEligibleWithdrawal(accounts).call();
      let reinvest_percentage = getEligibleWithdrawal.reivest;
      let withdrwal_percentage = getEligibleWithdrawal.withdrwal;

      let singleUplineBonusTaken = this.state.web3.utils.fromWei(userInfo.singleUplineBonusTaken, 'ether');
      let singleDownlineBonusTaken = this.state.web3.utils.fromWei(userInfo.singleDownlineBonusTaken, 'ether');

      let GetDownlineIncomeByUserId = await contract.methods.GetDownlineIncomeByUserId(accounts).call();
      GetDownlineIncomeByUserId = this.state.web3.utils.fromWei(GetDownlineIncomeByUserId, 'ether');
      GetDownlineIncomeByUserId = GetDownlineIncomeByUserId - singleDownlineBonusTaken;
      GetDownlineIncomeByUserId = parseFloat(GetDownlineIncomeByUserId).toFixed(5);

      let GetUplineIncomeByUserId = await contract.methods.GetUplineIncomeByUserId(accounts).call();
      GetUplineIncomeByUserId = this.state.web3.utils.fromWei(GetUplineIncomeByUserId, 'ether');
      GetUplineIncomeByUserId = GetUplineIncomeByUserId - singleUplineBonusTaken;
      GetUplineIncomeByUserId = parseFloat(GetUplineIncomeByUserId).toFixed(5);

      this.setState({ 
        userInfo,
        TotalBonus,
        totalWithdrawn,
        totalDeposits,
        totalInvested,
        totalUsers,
        //networkMain:true,
        YourAddress:YourAddress,
        YourAddressfull:YourAddressfull,
        user_referer:user_referer,
        balance,
        adminFee,
        reinvest_percentage,
        withdrwal_percentage,
        GetUplineIncomeByUserId,
        GetDownlineIncomeByUserId
      },async()=>{
        const { accounts, contract } = this.state;
        //let getEligibleLevelCountForUpline = await contract.methods.getEligibleLevelCountForUpline(accounts).call();
        // let uplineCount = getEligibleLevelCountForUpline.uplineCount;
        // let downlineCount = getEligibleLevelCountForUpline.downlineCount;        
        let uplineCount = 5;
        let downlineCount = 5;
        let communityLevels = [];
        let upline_users = [];
        let downline_users = [];
        let current_user = accounts;
        let userInfo = await contract.methods.users(current_user).call();
        for(let i=1;i<=uplineCount;i++){          
          if(current_user == userInfo.singleUpline) continue;
          current_user = userInfo.singleUpline;
          let emptyAddress = /^0x0+$/.test(current_user);
          if(emptyAddress) continue;
          userInfo = await contract.methods.users(current_user).call();
          let investment = this.state.web3.utils.fromWei(userInfo.amount, 'ether');
          let singleLegDistribution = await contract.methods.singleLegDistribution(i-1).call();
          let income = (investment*singleLegDistribution/100).toFixed(5);
          investment = parseFloat(investment).toFixed(5);
          upline_users.push({level:i,levelText:'Upline-'+i,type:'upline',username:current_user,investment:investment,income:income});
        }
        upline_users.sort((a, b) => b.level>a.level? 1 : -1);
        upline_users.map(function(val, index){
          communityLevels.push(val);
        });
        let currentUserInfo = await contract.methods.users(accounts).call();
        let investment = this.state.web3.utils.fromWei(currentUserInfo.amount, 'ether');
        let income = (investment/100).toFixed(5);
        income = (0).toFixed(5);
        investment = parseFloat(investment).toFixed(5);
        let main_user = {level:0,levelText:'You',type:'main_user',username:accounts,investment:investment,income:income};
        communityLevels.push(main_user);
        current_user = accounts;
        userInfo = await contract.methods.users(current_user).call();
        for(let i=1;i<=downlineCount;i++){
          if(current_user == userInfo.singleDownline) continue;
          current_user = userInfo.singleDownline;
          let emptyAddress = /^0x0+$/.test(current_user);
          if(emptyAddress) continue;
          userInfo = await contract.methods.users(current_user).call();
          let investment = this.state.web3.utils.fromWei(userInfo.amount, 'ether');
          let singleLegDistribution = await contract.methods.singleLegDistribution(i-1).call();
          let income = (investment*singleLegDistribution/100).toFixed(5);
          investment = parseFloat(investment).toFixed(5);
          downline_users.push({level:i,levelText:'Downline-'+i,type:'downline',username:current_user,investment:investment,income:income});
        }
        downline_users.map(function(val, index){
          communityLevels.push(val);
        });

        let sponsorList = [];
        let count = 0;
        for(let i=0;i<5;i++){
          let referral_stage = await contract.methods.referral_stage(accounts,i).call();
          let _investment = this.state.web3.utils.fromWei(referral_stage._investment, 'ether');
          //let ref_bonuses = await contract.methods.ref_bonuses(i).call();
          let percentage_amount = this.state.web3.utils.fromWei(referral_stage._bonus, 'ether'); //(_investment*ref_bonuses/100).toFixed(5);
          _investment = parseFloat(_investment).toFixed(5);
          percentage_amount = parseFloat(percentage_amount).toFixed(5);
          let _noOfUser = referral_stage._noOfUser;
          
          if(i == 0){
            count = _noOfUser;
          }
          let status = '';
          if(i>1 && count<4){
            status = 'Unqualified';
          }
          sponsorList.push({status:status,level:i+1,_investment:_investment,_noOfUser:_noOfUser,percentage_amount:percentage_amount});
        }
        

        this.setState({communityLevels, sponsorList});
        // console.log(communityLevels);
        
        // console.log(sponsorList);
         
      });     
    }

    doJoinNow = async () => {
      //const weiValue = this.state.web3.utils.toWei('1', 'ether');
      //const etherValue = this.state.web3.utils.fromWei('1000000000000000000', 'ether');
      const { accounts, contract } = this.state;
      let sponsor_address = this.state.sponsor_address;
      let userInfo = await contract.methods.users(accounts).call();
      if(userInfo.checkpoint != undefined && userInfo.checkpoint > 0){
        sponsor_address = userInfo.referrer;
      }
      else if(!sponsor_address){
        this.props.enqueueSnackbar("Sponsor Address is required!",{ variant: 'error' })
        this.setState({sponsor_addressError:true});
        return false;
      }

      if(!this.state.amountBNB){
        this.props.enqueueSnackbar("Amount is required!",{ variant: 'error' })
        this.setState({amountError:true});
        return false;
      }
      
      let balance = await this.state.web3.eth.getBalance(this.state.accounts);
      let balanceEthVal = this.state.web3.utils.fromWei(balance, 'ether');
      let amountBNB = this.state.amountBNB;
      if(sponsor_address){
        if(balanceEthVal >= amountBNB){
          try {
            this.setState({loading:true});
            this.state.contract.events.NewDeposit((err, eventResult) => {
              console.log('eventResult',eventResult);
              if (!err) {
                let uerAddress = this.state.accounts;
                if(eventResult.returnValues[0] == uerAddress){
                  this.fetchData();
                  this.setState({loading:false});
                  this.props.enqueueSnackbar("Joined Successfully!",{ variant: 'success' });                
                }              
              }else{
                console.log(err);
                this.setState({loading:false});
                this.props.enqueueSnackbar('Some Network Error Occurred!');              
              }
            });

            let weiValue = this.state.web3.utils.toWei(amountBNB, 'ether');
            let invest = await this.state.contract.methods.invest(sponsor_address).send(
              {
                from: this.state.accounts,
                value:weiValue
              }
            );
            console.log(invest);
            setTimeout(() => {
              if(this.state.loading){
                window.location.reload(false);
              }
            }, 10000);
          }
          catch(err) {
            this.setState({loading:false});
            this.props.enqueueSnackbar(err,{ variant: 'error' });
          }          
        }else{
          this.setState({loading:false});
          this.props.enqueueSnackbar("Insufficient BNB Balance!",{ variant: 'error' });          
        }        
      }else{
        this.setState({loading:false});
        this.props.enqueueSnackbar("Please enter sponsor address!",{ variant: 'error' });        
      }
    }

    doWithdrawal = async () => {
      if(this.state.TotalBonus > 0){
        this.setState({loading:true});
        this.state.contract.events.Withdrawn((err, eventResult) => {
          console.log('eventResult',eventResult);
          console.log('err',err);
          if (!err) {
            let uerAddress = this.state.accounts;
            if(eventResult.returnValues[0] == uerAddress){
              this.fetchData();
              this.setState({loading:false});
              this.props.enqueueSnackbar("Withdrawal Successfully!",{ variant: 'success' });                
            }              
          }else{
            console.log(err);
            this.setState({loading:false});
            this.props.enqueueSnackbar('Some Network Error Occurred!');              
          }        
        });

        let withdrawal = await this.state.contract.methods.withdrawal().send(
          { 
            from: this.state.accounts
          }
        );
        console.log('withdrawal',withdrawal); 
        setTimeout(() => {
          if(this.state.loading){
            window.location.reload(false);
          }
        }, 10000);
      }else{
        this.props.enqueueSnackbar('Insufficient balance!');
      }
           
    }

    
    render() {
      const { t } = this.props;
      return (
        <Frame withFooter={true}>
          <TopHeader />

          {this.state.loading ? (
                  
            <div className="loader-container">
              <div className="dotted-loader"></div>
            </div>
              
            ) : (null)}      

        <div className="banner_section">
          <div className="container">
              <div className="mb-5">
                <a href="/"><img className="logo" src="img/logo.png" alt="" /></a>
              </div>
            <p className="story_heading white_text mb-0">{t('mainContent.Banner_Sub_Head')}</p>
            <div className="banner_box">
              <h1><span>{t('mainContent.Banner_Head1')} </span> {t('mainContent.Banner_Head2')}</h1>
              <p>{t('mainContent.Banner_Desc')}</p>
              
              {/* <a className="theme_btn" href="#">Classic</a>
              <a className="theme_btn" href="#">Pro</a> */}
            </div>
            <ul className="Contributors_ul">
              <li>
                <h4>{t('mainContent.Contributors')}</h4>
                <h5>{this.state.totalUsers}</h5>
              </li>
              <li className="active">
                <h4>{t('mainContent.Contribution')}</h4>
                <h5>{this.state.totalInvested} {t('mainContent.BNB')}</h5>
              </li>
              <li>
                <h4>{t('mainContent.Collections')}</h4>
                <h5>{this.state.totalWithdrawn} {t('mainContent.BNB')}</h5>
              </li>
            </ul>
          </div>
        </div>
       
        <div className="pb-5 pt-5">
          <div className="container">
            <div className="row">
              <div className="col-md-10 col-lg-10">
                <p className="story_heading">{t('mainContent.About_Us')}</p>
                <hr />
                <h3 className="white_text story_text">{t('mainContent.About_Us_Desc')}</h3>
              </div>
              <div className="col-md-2 col-lg-2">
                <p className="small_text">{t('mainContent.About_Us_Desc2')}</p>
              </div>
            </div>
          </div>
        </div>

        <div className="pb-5 pt-5 circle_bg">
          <div className="container">
            <div className="row">
              <div className="col-md-5 col-lg-5">
                <p className="story_heading">{t('mainContent.BestBNB_Work')}</p>
                <div className="all_heading mb-4">
                  <h2><span>{t('mainContent.Our')} </span>{t('mainContent.Vision')}</h2>
                </div>
                <hr />
                <p className="small_text">{t('mainContent.BestBNB_Work_Desc')}</p>
                <ul className="list_ul">
                  <li>{t('mainContent.BestBNB_Work_Sub1')}</li>
                  <li>{t('mainContent.BestBNB_Work_Sub2')}</li>
                  <li>{t('mainContent.BestBNB_Work_Sub3')}</li>
                  <li>{t('mainContent.BestBNB_Work_Sub4')}</li>
                </ul>
              </div>
              <div className="col-md-7 col-lg-7">
                <div className="small_heading">
                  <h5>{t('mainContent.ID')} - {this.state.YourAddress}</h5>
                </div>
                <div className="row">
                  <div className="col-md-6 col-sm-6">
                    <div className="Personal_Details_inner active">
                      <h4>{t('mainContent.Single_leg_family')}</h4>
                      <p>{t('mainContent.Up')}</p>
                      <h5>{this.state.GetUplineIncomeByUserId} {t('mainContent.BNB')}</h5>
                    </div>
                  </div>
                  <div className="col-md-6 col-sm-6">
                    <div className="Personal_Details_inner">
                      <h4>{t('mainContent.Single_leg_family')}</h4>
                      <p>{t('mainContent.Down')}</p>
                      <h5>{this.state.GetDownlineIncomeByUserId} {t('mainContent.BNB')}</h5>
                    </div>
                  </div>
                  <div className="col-md-6 col-sm-6">
                    <div className="Personal_Details_inner">
                      <h4>{t('mainContent.Level_income_or')} </h4>
                      <p>{t('mainContent.Sponsor_income')}</p>
                      <h5>{this.state.userInfo.referrerBonus} {t('mainContent.BNB')}</h5>
                    </div>
                  </div>
                  <div className="col-md-6 col-sm-6">
                    <div className="Personal_Details_inner">
                      <h4>{t('mainContent.Your_wallet')} </h4>
                      <p>{t('mainContent.balance')}</p>
                      <h5>{this.state.TotalBonus} {t('mainContent.BNB')}</h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="pb-5 pt-5">
          <div className="container">
            <div className="row cus_row">
                <div className="col-md-4 col-6">
                  <div className="Personal_Details_inner">
                    <h4>{t('mainContent.Your_contribution')}</h4>
                    <h5>{this.state.userInfo.amount} {t('mainContent.BNB')}</h5>
                  </div>
                </div>
                <div className="col-md-4 col-6">
                  <div className="Personal_Details_inner active">
                    <h4>{t('mainContent.Your_collection')}</h4>
                    <h5>{this.state.userInfo.totalWithdrawn} {t('mainContent.BNB')}</h5>
                  </div>
                </div>
                <div className="col-md-4 col-6">
                  <div className="Personal_Details_inner">
                    <h4>{t('mainContent.Your_sponsor')}</h4>
                    <h5>{this.state.user_referer}</h5>
                  </div>
                </div>
              </div>
          </div>
        </div>

        <div className="pb-5 pt-5 bg_color_dark">
          <div className="container">
            <div className="row">
              <div className="col-md-7 col-lg-7">
                <div className="wallet_box">
                  <p className="story_heading mb-0">{t('mainContent.Register_to')}</p>
                    <div className="all_heading mb-4">
                      <h2><span>{t('mainContent.BNB')} </span> {t('mainContent.blockchain')}</h2>
                    </div>
                  
                    <div className="form-group mb-3">
                      <label>{t('mainContent.Sponsor_wallet_address')}</label>
                      {(this.state.userInfo.checkpoint != undefined && this.state.userInfo.checkpoint > 0) ?(
                        <input className="cus_input" type="text" placeholder={t('mainContent.Sponsor_wallet_address')}
                          readOnly
                          value={this.state.user_referer || ''}
                          onChange={(e)=>this.setState({sponsor_address:e.target.value})}
                        />
                      ):(
                        <input className="cus_input" type="text" placeholder={t('mainContent.Sponsor_wallet_address')}
                          value={this.state.sponsor_address || ''}
                          onChange={(e)=>this.setState({sponsor_address:e.target.value})}
                        />
                      )}
                    </div>
                    <ul className="trx_btn mb-4">
                      {defaultAmountArr.map((amount,key) => {return(
                        <li key={key}>
                          <button className={this.state.amountBNB==amount?'active':'inactive'} 
                            onClick={e => this.setState({amountBNB:amount})}>
                            {amount+' '+t('mainContent.BNB')}
                          </button>
                        </li>
                      )                              
                      })}
                    </ul>
                    <div className="mt_20">
                      <button onClick={this.doJoinNow} className="theme_btn">{t('mainContent.Register')}</button>
                    </div>
                 
                
                </div>
              </div>
              <div className="col-md-5 col-lg-5">
                <img className="bnb_icon" src="img/blockchain.png" alt="" />
              </div>
            </div>
          </div>
        </div>

        <div className="pb-5 pt-5 circle_bg">
          <div className="container">
            <div className="row">
              <div className="col-md-5 col-lg-5">
                <img className="bnb_icon" src="img/bnb-wallet.png" alt="" />
              </div>
              <div className="col-md-7 col-lg-7">
                <div className="wallet_box">
                    <div className="all_heading mb-4">
                      <h2><span>{t('mainContent.Wallet')} </span> {t('mainContent.balance')}</h2>
                    </div>
                
                    <div className="row">
                      <div className="col-md-12 col-lg-12 col-sm-12">
                        <div className="form-group mb-3">
                          <label className="white_label">{t('mainContent.Available')} {t('mainContent.BNB')} ({this.state.adminFee_percentage} % {t('mainContent.Admin_Fee')}: {this.state.adminFee})</label>
                          <input className="cus_input" type="text" readOnly value={this.state.balance || '0.00'} />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6 col-lg-6 col-sm-6">
                        <div className="form-group mb-3">
                          <label className="white_label">{t('mainContent.Reinvestment_Amount')} ({t('mainContent.BNB')})</label>
                          <input className="cus_input" type="text" readOnly 
                          value={parseFloat(this.state.reinvest_percentage*this.state.balance/100).toFixed(5) || '0.00'} />
                        </div>
                      </div>
                      <div className="col-md-6 col-lg-6 col-sm-6">
                        <div className="form-group mb-3">
                          <label>{t('mainContent.Withdrawable_Amount')} ({t('mainContent.BNB')})</label>
                          <input className="cus_input" type="text" readOnly
                          value={parseFloat(this.state.withdrwal_percentage*this.state.balance/100).toFixed(5) || '0.00'} />
                        </div>
                      </div>
                    </div>
                    <div className="form-group mb-3">
                      <p className="mb-0">{t('mainContent.Reinvestment_percentage')}: <strong>{this.state.reinvest_percentage}%</strong></p>
                      <p>{t('mainContent.Withdrawable_percentage')}: <strong>{this.state.withdrwal_percentage}% </strong></p>
                    </div>
                    <div className="mt_20">
                      <button onClick={this.doWithdrawal} className="theme_btn">{t('mainContent.Withdrawal')}</button>
                    </div>
                </div>
              </div>            
            </div>
          </div>
        </div>

        
        <div className="pb-5 pt-5 circle_bg2">
          <div className="container">
            <div className="table_box_bg">
              <div className="all_heading mb-4">
                <h2><span>{t('mainContent.Single_leg')} </span> {t('mainContent.Family')}</h2>
              </div>
              <div className="table-responsive">
                <table className="table">
                  <thead>
                    <tr>
                      <th>{t('mainContent.Level')}</th>
                      <th>{t('mainContent.User')} {t('mainContent.ID')}</th>
                      <th>{t('mainContent.Investment')}</th>
                      <th>{t('mainContent.Income')}</th>
                    </tr>
                  </thead>
                  <tbody>                       
                    {this.state.communityLevels.length ? (
                      this.state.communityLevels.length>0 ? (
                        this.state.communityLevels.map(function(val, index){
                          let class_name = 'lebel_'+val.level;
                          if(val.level == 0){
                            class_name = 'current_user';
                          }
                          return (
                            <tr key={`cl${index}`} className={class_name}>
                              <td style={{width:'120px'}}>{val.levelText}</td>
                              <td style={{width:'50%'}}>{val.username}</td>
                              <td>{val.investment} {t('mainContent.BNB')}</td>
                              <td>
                              {val.income} {t('mainContent.BNB')}
                              </td>
                            </tr>
                          )
                        })
                      ):(null)
                    ) : (
                    <tr>
                      <td colSpan="4" className="text-center">{t('mainContent.No_Data')}</td>
                    </tr>
                    )}
                  </tbody>
                </table>
              </div>
                <br />
              <div className="">
              <div className="all_heading mb-4">
                <h2><span>{t('mainContent.Family')} </span> {t('mainContent.list_in_levels')}</h2>
              </div>
              <div className="table-responsive">
                <table className="table mb-0">
                  <thead>
                    <tr>
                      <th> {t('mainContent.Level')} </th>
                      <th> {t('mainContent.Count')} </th>
                      <th> {t('mainContent.Invest_Amount')} </th>
                      <th> {t('mainContent.Income')} </th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.sponsorList.length ? (
                      this.state.sponsorList.length>0 ? (
                        this.state.sponsorList.map(function(val, index){
                          let class_name = 'lebel_'+val.level;
                          if(val.level == 0){
                            class_name = 'current_user';
                          }
                          return (
                            <tr key={`sl${index}`} className={class_name}>
                              <td style={{width:'120px'}}>
                                {t('mainContent.Level')}-{val.level}
                              </td>
                              <td>{val._noOfUser}</td>
                              <td>{val._investment} {t('mainContent.BNB')}</td>
                              <td>{val.percentage_amount} {t('mainContent.BNB')}</td>
                            </tr>
                          )
                        })
                      ):(null)
                    ) : (
                    <tr>
                      <td colSpan="4" className="text-center">{t('mainContent.No_Data')}</td>
                    </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
            </div>

            
          </div>
        </div>

        <div className="pb-5 pt-5 circle_bg">
          <div className="container">
              <div class="video_box">
                <a class="popup-vimeo" href="video/Best-BNB.mp4">
                  <img class="video_img" src="img/video_img.jpg" alt="" />
                  <span class="video_icon"><i class="fa fa-play"></i></span>
                </a>
              </div>        
          </div>
        </div>

        <div className="pb-5 pt-5 circle_bg3">
          <div className="container">
            <div className="row">
              <div className="col-md-5 col-lg-5">
                <p className="story_heading">{t('mainContent.BestBNB_Document')}</p>
                <div className="all_heading mb-4">
                  <h2><span>{t('mainContent.Access')} </span> {t('mainContent.the_Whitepaper_here')}</h2>
                </div>
                <hr />
                <p className="small_text">{t('mainContent.Whitepaper_Desc')}</p>
              
              </div>
              <div className="col-md-7 col-lg-7">
                <ul className="pdf_ul">
                  <li>
                    <a href="pdf/BestBNB.pdf" target={'_blank'}>
                      <span><i className="fa fa-file-pdf-o"></i></span>
                      <h4>{t('mainContent.eng_pdf')}</h4>
                    </a>
                  </li>
                  <li>
                    <a href="pdf/BestBNB_Chinese.pdf" target={'_blank'}>
                      <span><i className="fa fa-file-pdf-o"></i></span>
                      <h4>{t('mainContent.chi_pdf')}</h4>
                    </a>
                  </li>
                  <li>
                    <a href="pdf/BestBNB_German.pdf" target={'_blank'}>
                      <span><i className="fa fa-file-pdf-o"></i></span>
                      <h4>{t('mainContent.german_pdf')}</h4>
                    </a>
                  </li>
                  <li>
                    <a href="pdf/BestBNB_Russian.pdf" target={'_blank'}>
                      <span><i className="fa fa-file-pdf-o"></i></span>
                      <h4>{t('mainContent.russian_pdf')}</h4>
                    </a>
                  </li>
                  <li>
                    <a href="pdf/BestBNB_spanish.pdf" target={'_blank'}>
                      <span><i className="fa fa-file-pdf-o"></i></span>
                      <h4>{t('mainContent.spanish_pdf')}</h4>
                    </a>
                  </li>
                  <li>
                    <a href="pdf/BestBNB_Tagalog.pdf" target={'_blank'}>
                      <span><i className="fa fa-file-pdf-o"></i></span>
                      <h4>{t('mainContent.tagalog_pdf')}</h4>
                    </a>
                  </li>
                  <li>
                    <a href="pdf/BestBNB_Vietnamese.pdf" target={'_blank'}>
                      <span><i className="fa fa-file-pdf-o"></i></span>
                      <h4>{t('mainContent.vietn_pdf')}</h4>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>


        <div className="pb-5 pt-5 bg_color_dark">
          <div className="container">
            <div className="container">
              <div className="row">
                <div className="col-md-6 col-lg-6">
                  <div className="all_heading mb-4">
                    <h2><span>{t('mainContent.Your')} </span> {t('mainContent.Referral_link')}</h2>
                  </div>
                </div>
                <div className="col-md-6 col-lg-6">
                <h5 className="text-center mb-3 white_text word-break">https://classic.bestbnb.io/{this.state.YourAddress}</h5>
                  
                  <div className="text-center">
                    <CopyToClipboard text={`https://classic.bestbnb.io/${this.state.YourAddressfull}`}
                      onCopy={() => this.props.enqueueSnackbar(`${t('mainContent.Copied_Successfully')}`,{ variant: 'info' })
                      }>
                      <button className="theme_btn">{t('mainContent.Copy_Link')}</button>
                    </CopyToClipboard>                      
                  </div>

                </div>
              </div>
            </div>
            
          </div>
        </div>




      


        </Frame>
      );
    }
  }

  const useStyles = {
    root: {
      flexGrow: 1
    }
  }


  //export default withRouter(withStyles(useStyles)(withSnackbar(Home)));
  //export default Home;
  export default withRouter(withTranslation()(withStyles(useStyles)(withSnackbar(Home))));